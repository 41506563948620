import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Slider from '../Slider';
import { Link } from 'react-router-dom';

function Home() {
  const images = ['2', '3', '10', '2_champ2', '2_cheese', '2_print', '2_tent', '2_tent2', '2_vint'];
  return (
    <>
      <HeroSection />
      <br />
      <div className="container">
        <Slider category="mariage" pics={images} />
      </div>
      <div className="container" style={{ textAlign: 'center' }}>
        <h2 style={{ fontFamily: "Trebuchet MS", fontSize: "1.5rem", marginTop: "1rem" }}>
          <Link to="/contact" style={{
            color: '#466',
            textDecoration: 'none',
            fontFamily: "Trebuchet MS",
            fontSize: "1.5rem"
          }}>Prenez contact</Link> pour discuter de votre projet
        </h2>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default Home;

